jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-left"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	API.bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Add dropdown class to main nav items
	$(".main-nav-li:has('.dropdown-toggle')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link.w-nav-link").click(function(event) {
			if (!$(this).parents(".has-dropdown").hasClass("open")) {
				$(".main-nav-li").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-li.has-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767) {
		$("#wrap").fitVids({
			ignore: "nofit"
		});
	}

	// Redirect popup
	$('.close-icon-button').click(function() {
		$('.redirect-modal').hide();
	})
	$('.redirect-modal-content .buy-now').click(function() {
		$('.redirect-modal').hide();
	})

	/* Sticky header */
	$(window).scroll(function() {
		$('.navbar').toggleClass("sticky", $(this).scrollTop() > 145);
	});
});

/* eslint-disable */
/**
 * ----------------------------------------------------------------------
 * Webflow: Interactions: Init
 */
Webflow.require('ix').init([{
		"slug": "cb-hover",
		"name": "CB hover",
		"value": {
			"style": {},
			"triggers": [{
					"type": "hover",
					"selector": ".cb-caption",
					"stepsA": [{
						"height": "100%",
						"transition": "height 400ms ease 0"
					}],
					"stepsB": [{
						"height": "80px",
						"transition": "height 400ms ease 0"
					}]
				},
				{
					"type": "hover",
					"selector": ".cb-paragraph p",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				},
				{
					"type": "hover",
					"selector": ".cb-leaf-1",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				}
			]
		}
	},
	{
		"slug": "cb-hover-2",
		"name": "CB Hover 2",
		"value": {
			"style": {},
			"triggers": [{
					"type": "hover",
					"selector": ".cb-caption-2",
					"stepsA": [{
						"height": "100%",
						"transition": "height 400ms ease 0"
					}],
					"stepsB": [{
						"height": "80px",
						"transition": "height 400ms ease 0"
					}]
				}, {
					"type": "hover",
					"selector": ".cb-paragraph-2 p",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				},
				{
					"type": "hover",
					"selector": ".cb-leaf-2",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				}
			]
		}
	},
	{
		"slug": "cb-hover-3",
		"name": "CB Hover 3",
		"value": {
			"style": {},
			"triggers": [{
					"type": "hover",
					"selector": ".cb-caption-3",
					"stepsA": [{
						"height": "100%",
						"transition": "height 400ms ease 0"
					}],
					"stepsB": [{
						"height": "80px",
						"transition": "height 400ms ease 0"
					}]
				}, {
					"type": "hover",
					"selector": ".cb-paragraph-3 p",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				},
				{
					"type": "hover",
					"selector": ".cb-leaf-3",
					"stepsA": [{
						"opacity": 1,
						"transition": "opacity 400ms ease 0"
					}],
					"stepsB": [{
						"opacity": 0,
						"transition": "opacity 400ms ease 0"
					}]
				}
			]
		}
	}
]);
/* eslint-enable */
